import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import './App.css';
import {v4 as uuidv4} from 'uuid';
import {UpdateInterface} from "./types/Api";
import {useCookies} from "react-cookie";

function App() {
    const isMounted = useRef<boolean>(false)
    const uuid = useRef<string>("")

    const [cookies, setCookie, removeCookie] = useCookies(['omnilive_remote']);

    const [rangeValue, setRangeValue] = useState<number>(0)

    const handleUpdateRange = (e: ChangeEvent<HTMLInputElement>) => {
        const eventValue = parseInt(e.target.value)
        if (eventValue > rangeValue) {
            setRangeValue(eventValue)
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const adminQuery = urlParams.get('admin');

        if (!isMounted.current) {
            if (cookies.omnilive_remote) {
                console.log("Using existing cookie", cookies.omnilive_remote)
                uuid.current = cookies.omnilive_remote
            } else {
                uuid.current = uuidv4()
                console.log("Creating new cookie", uuid.current)
                setCookie("omnilive_remote", uuid.current)
            }

            fetch(`https://remote-backend.omnilive.tv/api/v1/update${adminQuery ? `?admin=${adminQuery}` : ""}`, {
                method: 'POST',
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    uuid: uuid.current,
                    value: rangeValue
                })
            })
                .then(res => res.json())
                .then((data: UpdateInterface) => {
                    if (data.locked) {
                        setRangeValue(0)
                    }
                    // console.log(data)
                })


            isMounted.current = true
            return
        }

        if (rangeValue % 5 === 0 || adminQuery) {
            fetch(`https://remote-backend.omnilive.tv/api/v1/update${adminQuery ? `?admin=${adminQuery}` : ""}`, {
                method: 'POST',
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    uuid: uuid.current,
                    value: rangeValue
                })
            })
                .then(res => res.json())
                .then((data: UpdateInterface) => {
                    if (data.locked) {
                        setRangeValue(0)
                    }
                    // console.log(data)
                })

        }

        if (rangeValue % 10 === 0) {
            const bubbles = rangeValue / 10
            for (let i = 1; i <= 10; i++) {
                const bubble = document.getElementById(`bubble_${i}`)

                bubble?.classList.remove("bg-red", "bg-white", "bg-green")
                if (bubbles >= i) {
                    bubble?.classList.add("bg-green")
                } else if (i === 1 || i === 10) {
                    bubble?.classList.add("bg-red")
                } else {
                    bubble?.classList.add("bg-white")
                }
            }
        }

    }, [rangeValue]);

    return (
        <div className="App">
            <header className="App-header">

                <div className="text-container">
                    <p>Remontez la manette pour activer le premier roulage du Grand Paris Express</p>
                </div>

                <div className="bubbles-container">
                    <div className="bubble bg-red" id="bubble_1"></div>
                    <div className="bubble bg-white" id="bubble_2"></div>
                    <div className="bubble bg-white" id="bubble_3"></div>
                    <div className="bubble bg-white" id="bubble_4"></div>
                    <div className="bubble bg-white" id="bubble_5"></div>
                    <div className="bubble bg-white" id="bubble_6"></div>
                    <div className="bubble bg-white" id="bubble_7"></div>
                    <div className="bubble bg-white" id="bubble_8"></div>
                    <div className="bubble bg-white" id="bubble_9"></div>
                    <div className="bubble bg-red" id="bubble_10"></div>
                </div>

                <div className="slider-container">
                    <input type="range" min="0" max="100" value={rangeValue} className="slider" id="omni-range"
                           onChange={handleUpdateRange}/>
                </div>
            </header>
        </div>
    );
}

export default App;
